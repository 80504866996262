import { createWebHistory, createRouter } from 'vue-router';
import adminRoutes from './AdminRoutes';
import authRoutes from './authRoutes';
import store from '@/vuex/store';

const routes = [
  {
    name: 'Admin',
    path: '/',
    component: () => import(/* webpackChunkName: "admin" */ '@/layout/withAdminLayout.vue'),
    children: [...adminRoutes],
    meta: { auth: false },
  },
  {
    name: 'Auth',
    path: '/auth',
    component: () => import(/* webpackChunkName: "auth" */ '@/layout/withAuthLayout.vue'),
    children: [...authRoutes],
    meta: { auth: true },
  },
  {
    path: '/unauthorized',  // Rota de fallback, captura todos os caminhos não tratados
    name: 'unauthorized',
    component: () => import('@/view/pages/401.vue')
  },
  {
    path: '/:catchAll(.*)*',  // Rota de fallback, captura todos os caminhos não tratados
    name: 'NotFound',
    component: () => import('@/view/pages/404.vue')
  }
];

const router = createRouter({
  history: createWebHistory(
    process.env.NODE_ENV === 'production'
      ? process.env.VUE_APP_SUB_ROUTE
        ? process.env.VUE_APP_SUB_ROUTE
        : process.env.BASE_URL
      : process.env.BASE_URL,
  ),
  linkExactActiveClass: 'active',
  routes,
});

router.beforeEach((to, from, next) => {
  /* eslint-disable */
  
  if (to.meta.auth && store.getters['auth/loggedIn']) {
    next({ to: '/' });
  } else if (!to.meta.auth && !store.getters['auth/loggedIn']) {
    next({ name: 'login' });
  } else {
      const permissionsNeeded = to.meta.requiredPermissions;
      
      if (!permissionsNeeded || permissionsNeeded.length == 0 || store.getters['auth/temPermissao'](permissionsNeeded)) {
        next();  // Permissão concedida
      } else {
        let d = btoa(JSON.stringify({ from: from.fullPath, redirect: to.fullPath }));
        next({ path: '/unauthorized', query: {q: d} });
      }

    next();
  }
  window.scrollTo(0, 0); // reset scroll position to top of page
  next();
});

export default router;
