import { Api } from "@/services/Api.js";
// import window.axios from 'axios';

export class LogService extends Api {

    listar(queryString) {
        let headers = this.getHeaders()
        queryString = super.getQueryString(queryString);
        return window.axios.get(`${this.API_URI}/api/log-atividades?${queryString}`, { headers: headers });
    }

    listarEntidades() {
        let headers = this.getHeaders()
        return window.axios.get(`${this.API_URI}/api/log-atividades/entidades`, { headers: headers });
    }

   

}