import { format, parseISO } from 'date-fns';

export function formatDate(data){
    if (!data)
        return data
    
    if(typeof data === 'string'){
        data = parseISO(data);
    }

    return format(data, 'dd/MM/yyyy');

}
export function formatDateTime(data){
    if (!data)
        return data

    if(typeof data === 'string'){
        data = parseISO(data);
    }

    return format(data, 'dd/MM/yyyy HH:mm:ss');

}