<template>
  <span :style="data.style" style="padding:5px 7px 5px 7px; text-align: center; display: inline-block; border-radius: 10px; font-size: 12px; font-weight: 500;">
    {{ label }}
  </span>
</template>

<script>
import { defineComponent, computed } from "vue";

export default defineComponent({
  name: "Badge",
  props: {
    label: {
      type: String,
      default: '' 
    },
    type: {
      type: String,
      default: '' 
    }
  },
  setup(props) {

    const data = computed(() => {
      switch(props.type){
         case 'warning'  : return{
            style:{
              'background-color': '#FA8B0C15',
              'color': '#FA8B0C',
            },
        };
        case 'success' : return{
            style:{
              'background-color': '#01B81A15',
              'color': '#01B81A',
            },
        };
        case 'error'  : return{
            style:{
              'background-color': '#FF0F0F15',
              'color': '#FF0F0F',
            },
        }
        default  : return{
            style:{
              'background-color': '#FF0F0F15',
              'color': '#FF0F0F',
            },
        };
      }
    });

    return {
      data
    };
  },
});
</script>
