export class Api {

    constructor() {
        this.API_URI = process.env.VUE_APP_API_URI || ''
        this.CLIENT_ID = process.env.VUE_APP_CLIENT_ID || ''
        this.CLIENT_SECRET = process.env.VUE_APP_CLIENT_SECRET || ''
    }

    getToken(){
        const access_token = JSON.parse(window.localStorage.getItem('auth.access_token'))
        if(access_token){
            return access_token.access_token
        }

        return null
    }

    getHeaders(){
        let token = this.getToken();
        return {
            'Authorization': `Bearer ${token}`,
            'Content-Type':  'application/json'
        };
    }
    
    getQueryString(object) {

        if (!object)
            return ''

        if (typeof (object) === 'string')
            return object;

        if (typeof (object) === 'object')
            object = {...object}

        let queryString = ''

        // Monta a querystring 
        Object.keys(object).forEach((key) => {
            if (object[key] !== null && object[key] !== undefined && object[key] !== '') {
                if (queryString.length > 0)
                    queryString += '&'
                queryString += key + '=' + encodeURIComponent(object[key]);
            }
        });

        return queryString;

    }
}
  