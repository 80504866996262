import { createStore } from 'vuex';
import auth from './modules/authentication/auth'
import themeLayout from './modules/themeLayout/actionCreator';
import lang from './modules/lang/langModule';
import cambios from './modules/cambio/cambioModule';
import loader from './modules/loader/loaderModule';

export default createStore({
  modules: {
    auth,
    themeLayout,
    lang,
    cambios,
    loader
  },
});
