import { createApp } from 'vue';
import {mask} from 'vue-the-mask'
import App from '../App.vue';
import { formatDate } from '@/helpers/utils';


const app = createApp(App);
app.directive('mask', mask);

app.config.globalProperties.$formatDate = formatDate;


export default app;
