<template>
  <a-form-item :label="label" :name="name" :class="{'ant-form-item-has-error': errors }">
    <slot></slot>
    <div v-if="errors" role="alert" class="ant-form-item-explain-error" style="">
      {{ errors }}
    </div>
    
  </a-form-item>  
</template>

<script>
import VueTypes from "vue-types";
import { defineComponent, computed } from "vue";

export default defineComponent({
  name: "FormItem",
  props: {
    label: VueTypes.string.def(""),
    name: VueTypes.string.def(""),
    validator: VueTypes.object.def(null)
  },
  setup(props) {
    const errors = computed(() => {
      if(!props.validator || !props.validator.$invalid)
        return ''

      let errors = props.validator.$errors.map(error => {
        switch(error.$validator){
          case 'required':
          case 'requiredIf':  return 'Campo obrigatório';
          case 'minLength': return `Mínimo: ${error.$params.min} caracteres`
          case 'maxLength': return `Máximo: ${error.$params.max} caracteres`
          case 'minValue': return `Valor Mínimo: ${error.$params.min}`
          case 'maxValue': return `Valor Máximo: ${error.$params.max}`
          case 'email': return `Email inválido`
          case 'sameAs': return `O valor está diferente do campo "${error.$params.equalTo}"`;
          case 'cpfValidator': return `CPF inválido`;
          case 'cnpjValidator': return `CNPJ inválido`;
          case 'cpfCnpjValidator': return `CPF ou CNPJ inválido`;
          case 'integer': return 'Somente Números inteiros';
          default : return error.$message;

        }
      });
      return errors.join(', ')

    });


    return {
      errors
    };
  },
});
</script>
