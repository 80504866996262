const state = () => ({
  lang: JSON.parse(window.localStorage.getItem('lang.default')),
});


const mutations = {
  SET_LANGUAGE(state, newValue) {
      state.lang = newValue
      window.localStorage.setItem('lang.default', JSON.stringify(newValue))
  } 
}

const getters = {
  // Whether the user is currently logged in.
  getLang(state) {
    let x
      if(!state || !state.lang){
        x = 'ptBR'
      }else{

        x =  state.lang
      }
      console.log(x)

      return x
  },

}

export default {
  namespaced: true,
  state,
  mutations,
  getters
};
