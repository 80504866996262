import { LoginService } from '@/services/Auth/LoginService'
import router from '@/routes/protectedRoute';

const state = () => ({
    access_token: JSON.parse(window.localStorage.getItem('auth.access_token')),
    user: JSON.parse(window.localStorage.getItem('auth.user')),
    menus: JSON.parse(window.localStorage.getItem('auth.menus')),
    permissoes: JSON.parse(window.localStorage.getItem('auth.permissoes')),
    loading: false
})

const mutations = {
    SET_CURRENT_ACCESS_TOKEN(state, newValue) {
        state.access_token = newValue
        saveState('auth.access_token', newValue)
    },
    SET_CURRENT_USER(state, newValue) {
        state.user = newValue
        saveState('auth.user', newValue)
    },
    SET_CURRENT_MENUS(state, newValue) {
        state.menus = newValue
        saveState('auth.menus', newValue)
    },
    SET_CURRENT_PERMISSOES(state, newValue) {
        state.permissoes = newValue
        saveState('auth.permissoes', newValue)
    },
    SET_LOADING(state, newValue) {
        state.loading = newValue;
    },
}

const getters = {
    // Whether the user is currently logged in.
    loggedIn(state) {
        if(!state || !state.access_token || !state.access_token.access_token)
            return false
        if(new Date() > new Date(state.access_token.access_token_expiration)){
            return false
        }
        return true
    },

    getLoading(state){
        return state.loading
    },

    accessToken({state, dispatch}) {
        
        if(!state || !state.access_token || !state.access_token.access_token)
            return null
        if(new Date() > new Date(state.access_token.access_token_expiration)){
            dispatch('checkValidToken')
            return null
        }
        return !!state.access_token.access_token
    },

    temPermissao: (state) => (permissoes) => {
        return state.permissoes.includes('Super Admin') || permissoes.some(permission => state.permissoes.includes(permission));
    }

}

const actions = {
   
    checkValidToken({state, commit}){
        if(new Date() > new Date(state.access_token.access_token_expiration)){
            commit('SET_CURRENT_ACCESS_TOKEN', null)
            commit('SET_CURRENT_USER', null)
            commit('SET_CURRENT_MENUS', null)
            commit('SET_CURRENT_PERMISSOES', null)
            removeState('auth.access_token')
            removeState('auth.menus')
            removeState('auth.permissoes')
            removeState('auth.user')
            // abrir modal de re-login
            return
        }
    },

    ping({ commit, getters}){
        if(!getters.loggedIn)
            return;
        const loginService = new LoginService();
        loginService.ping().then( () =>  console.log("Logado.")

        ).catch(e => {
            if(e.response && e.response.status == 401){
                
                commit('SET_CURRENT_ACCESS_TOKEN', null)
                commit('SET_CURRENT_USER', null)
                commit('SET_CURRENT_MENUS', null)
                commit('SET_CURRENT_PERMISSOES', null)
                router.push("/auth/login"); 

            }
        })
    },

    // Logs in the current user.
     logIn({ commit }, { username, password } = {}) {        
        // if (getters.loggedIn){
        //     return true
        // }  
        commit('SET_CURRENT_ACCESS_TOKEN', null)
        commit('SET_CURRENT_USER', null)
        commit('SET_CURRENT_MENUS', null)
        commit('SET_CURRENT_PERMISSOES', null)

        return new Promise((resolve, reject) => {
            
                commit('SET_LOADING', true);
                const loginService = new LoginService();
                loginService.loginUser(username, password)
                    .then((response) => {
                        let access_token = {access_token: response.data.access_token }
                        let user = response.data.user
                        console.log('Response no THEN', response)
                
                        /**
                         * Calculo da validade do token
                         */
                        let payloadBase64Url = access_token.access_token.split('.')[1];
                        let payloadBase64 = payloadBase64Url.replace(/-/g, '+').replace(/_/g, '/');
                        let payload = JSON.parse(atob(payloadBase64));
                        access_token.access_token_expiration = new Date(payload.exp * 1000);
        
                        commit('SET_CURRENT_ACCESS_TOKEN', access_token)
                        commit('SET_CURRENT_USER', user)
                        commit('SET_CURRENT_MENUS', response.data.menus)
                        commit('SET_CURRENT_PERMISSOES', response.data.permissoes)
                        commit('SET_LOADING', false);
                        resolve(true) 
                    })
                    .catch( e => {
                        console.log('Response no catch', e)
                        commit('SET_LOADING', false);
                        reject (e.response)

                    })
        });
        
    },

    me({ getters,commit }) {
    
        if(!getters.loggedIn)
            return;
    
        console.log(state.accessToken)
        const loginService = new LoginService();
        loginService.me()
            .then((response) => {
                commit('SET_CURRENT_USER', response.data.user)
                commit('SET_CURRENT_MENUS', response.data.menus)
                commit('SET_CURRENT_PERMISSOES', response.data.permissoes)
            })
            .catch( e => {
                console.log('Response no catch', e)
            })
        
        
    },

    logOut({ commit }) {
        return new Promise((resolve, reject) => {
                const loginService = new LoginService();
                loginService.logout().then(() => {
                commit('SET_CURRENT_ACCESS_TOKEN', null)
                removeState('auth.access_token')
                console.log("Deslogado com sucesso");
                router.push("/auth/login"); 
                resolve(true);
            }).catch((error) => {
                console.log("Erro ao deslogar", error);
                commit('SET_CURRENT_ACCESS_TOKEN', null)
                removeState('auth.access_token')
                console.log("Deslogado com sucesso");
                router.push("/auth/login"); 
                resolve(true);
                reject(error);
            })
        });
    },

    // register the user
    register({ commit, dispatch, getters }, { email, password } = {}) {
        if (getters.loggedIn) 
            return dispatch('validate')
        
        const loginService = new LoginService();
        return loginService.registerUser(email, password).then((response) => {
            const access_token = response
            commit('SET_CURRENT_ACCESS_TOKEN', access_token)
            return access_token
        });
    },

    // register the user
    // eslint-disable-next-line no-unused-vars
    resetPassword({ dispatch, getters }, { email } = {}) {
        if (getters.loggedIn) 
            return dispatch('validate')
        
        const loginService = new LoginService();
        return loginService.forgetPassword(email).then((response) => {
            const message = response.data
            return message
        });
    },

    // Validates the current user's token and refreshes it
    // with new data from the API.
    // eslint-disable-next-line no-unused-vars
    validate({ commit, state }) {
        if (!state.access_token) return Promise.resolve(null)
        
        const loginService = new LoginService();
        const access_token = loginService.getAuthenticatedUser();
        commit('SET_CURRENT_ACCESS_TOKEN', access_token)
        return access_token;
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
  };

// ===
// Private helpers
// ===

function saveState(key, state) {
    window.localStorage.setItem(key, JSON.stringify(state))
}

function removeState(key){
    window.localStorage.removeItem(key)
}
