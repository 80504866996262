const state = () => ({
  enableLoader: true,
});


const mutations = {
  ENABLE_LOADER(state) {
    state.enableLoader = true
  } ,
  DISABLE_LOADER(state) {
    state.enableLoader = false
  } 
}

const getters = {
  // Whether the user is currently logged in.
  getLoader(state) {
    return state.enableLoader
  },

}

export default {
  namespaced: true,
  state,
  mutations,
  getters
};
