<template>
  <DataTableStyleWrap>
    <div class="ninjadasj-datatable">
      <TableWrapper class="table-data-view table-responsive">
        <a-table
          v-if="rowSelection"
          :row-selection="rowSelections"
          :pagination="false"
          :data-source="tableData"
          :columns="columns"
          :locale="{ emptyText: 'Nenhum registro encontrado' }"
          class="custom-table"
        />

        <a-table
          v-else
          :pagination="false"
          :data-source="tableData"
          :columns="columns"
          :locale="{ emptyText: 'Nenhum registro encontrado' }"
          @delete-row="confirmDelete"
          class="custom-table"
        />
      </TableWrapper>
    </div>
  </DataTableStyleWrap>
</template>
<script>
import { defineComponent, computed, ref, unref } from "vue";
import VueTypes from "vue-types";
import { DataTableStyleWrap } from "./Style";
import { TableWrapper } from "../../view/styled";
import { useStore } from "vuex";

export default defineComponent({
  methods: {
    confirmDelete(record) {
      this.$emit("confirm-delete", record);
    },
  },
  components: { DataTableStyleWrap, TableWrapper },
  props: {
    filterOption: VueTypes.bool,
    filterOnchange: VueTypes.bool,
    rowSelection: VueTypes.bool,
    tableData: VueTypes.array,
    columns: VueTypes.array,
  },
  setup() {
    const { dispatch } = useStore();

    const handleIdSearch = (e) => {
      const id = e.currentTarget.value;
      dispatch("dataLiveFilter", { value: id, key: "id" });
    };
    const handleStatusSearch = (value) => {
      dispatch("dataLiveFilter", { value, key: "status" });
    };

    const handleDataUser = (e) => {
      const { value } = e.currentTarget;
      dispatch("dataLiveFilter", { value, key: "name" });
    };

    const handleSearch = () => {
      const id = document.querySelector(".ninjadash-data-id").value;
      const status = document.querySelector(
        ".ninjadash-data-status .ant-select-selection-item"
      ).title;
      dispatch("filterWithSubmit", { id, status });
    };

    const selectedRowKeys = ref([]); // Check here to configure the default column

    const onSelectChange = (changableRowKeys) => {
      console.log("selectedRowKeys changed: ", changableRowKeys);
      selectedRowKeys.value = changableRowKeys;
    };

    const rowSelections = computed(() => {
      return {
        selectedRowKeys: unref(selectedRowKeys),
        onChange: onSelectChange,
        hideDefaultSelections: true,
      };
    });

    return {
      handleIdSearch,
      handleStatusSearch,
      handleDataUser,
      handleSearch,
      rowSelections,
    };
  },
});
</script>
<style>
.custom-table tbody tr:hover {
  background-color: #f2f2f2; /* Cor de fundo ao passar o mouse */
  cursor: pointer; /* Mudar o cursor para indicar interatividade */
}
</style>
