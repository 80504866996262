<template>
  <div>
    
    <sdButton
        html-type="button"
        @click.prevent="showLog()"
        class="btn-outlined"
        size="lg"
        :outlined="true"
        type="light"
        style="margin: 10px; min-width: 120px;"
        
      >
        {{label_button}}
      </sdButton>



    <a-modal
      :visible="data.showModal"
      title="Log de Alterações"
      @ok="data.showModal = false"
      @cancel="data.showModal = false"
      cancel-text="Fechar"
      width="85%"
      heigth="80%"
      >
      <template #footer>
      <slot name="footer">
        <sdButton
          type="white"
          :outlined="true"
          key="back"
          @click="data.showModal = false"
          html-type="button"
        >
          Fechar
        </sdButton>
      </slot>
    </template>
      <sdPageHeader :title="`Log de Alterações ${entidade_label}`" class="ninjadash-page-header-main" />
      <Main>
        <a-row :gutter="15">
          <a-col :xs="24">
            <BorderLessHeading>
              <sdCards :title="`Log de Alterações ${entidade_label}`">
                <a-row :gutter="15">
                  <a-col :xs="16">
                    <DataTableStyleWrap>
                      
                      <div
                        class="ninjadash-datatable-filter__left"
                      >
                        <a-input
                          @change="debounceGetData"
                          v-model:value="data.filter.properties"
                          size="default"
                          class="custom-input"
                          placeholder="Pesquisar"
                        >
                          <template #prefix>
                            <unicon name="search"></unicon>
                          </template>
                        </a-input>
                        
                      </div>
                    </DataTableStyleWrap>
                  </a-col>
                  <a-col :xs="8">
                    <div class="ninjadash-datatable-filter__right">
                        <sdLoading :isVisible="data.loading" />
                    </div>
                  </a-col>
                </a-row>
              </sdCards>
              <sdCards>              
                <DataTables
                  :filterOption="true"
                  :filterOnchange="true"
                  :tableData="dataSource"
                  :columns="dataColumns"
                  :rowSelection="false"
                  @confirm-delete="confirmDelete"
                />

                  <a-pagination
                    show-size-changer
                    v-model:current="data.filter.page"
                    v-model:pageSize="data.filter.per_page"
                    showSizeChanger
                    :onShowSizeChange="onShowSizeChange"
                    :defaultCurrent="1"
                    :total="data.metaData.total"
                    :locale="{ items_per_page: 'por página' }"
                  />
              </sdCards>
            </BorderLessHeading>
          </a-col>
        </a-row>
      </Main>
    </a-modal>

  </div>
</template>

<script>
import VueTypes from "vue-types";
import { defineComponent, reactive, computed, toRefs  } from "vue";
import { Main, BorderLessHeading } from "@/view/styled";
import DataTables from "@/components/table/DataTable.vue";
import { LogService } from "@/services/Administracao/LogService";
import { DataTableStyleWrap } from "@/components/table/Style";
import { resolveError } from '@/helpers/responseHelper'
import { formatDateTime } from '@/helpers/utils'
const logService = new LogService();

const dataColumns = [
  {
    title: "Id",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "Usuário",
    dataIndex: "causer",
    key: "causer",
  },
  {
    title: "Evento",
    dataIndex: "event",
    key: "event",
  },
  {
    title: "Data",
    dataIndex: "created_at",
    key: "created_at",
  },
  {
    title: "Alterações",
    dataIndex: "alteracoes",
    key: "alteracoes",
  }

];


export default defineComponent({
  name: "Log",
  props: {
    entidade: VueTypes.string.def("").isRequired,
    entidade_label: VueTypes.string.def("").isRequired,
    entidade_id: VueTypes.integer.def(true).isRequired,
    label_button: VueTypes.string.def("Log de Alterações")
  },
  components: {
    Main,
    DataTables,
    BorderLessHeading,
    DataTableStyleWrap,
  },
  setup(props) {    

    const { entidade, entidade_id } = toRefs(props);


    const data = reactive({
      data: [],
      metaData: {},
      debounce: null,
      loading: false,
      filter: {
        page: 1,
        per_page: 20,
        order_by: 'id',
        order: 'desc',
        properties: ''
      },
      showModal: false,
    });

    // watch(
    //   () => data.filter.page,
    //   () => {
    //     getData();
    //   }
    // );

    const debounceGetData = () => {
      clearTimeout(data.debounce);
      data.debounce = setTimeout(() => {
        data.filter.page = 1;
        getData();
      }, 800);
    };

    const getData = () => {
      data.loading = true;
      let filter = {...data.filter, subject_type: entidade.value, subject_id: entidade_id.value }
      logService
        .listar(filter)
        .then((response) => {
          data.data = [...response.data.data];
          delete response.data.data
          data.metaData = response.data;
        })
        .catch((e) => {
          resolveError(e)
        })
        .finally(() => (data.loading = false));
    };

    const dataSource = computed(() =>
      data.data.map((item) => {
        return {
          id: `${item.id}`,
          key: item.id,
          causer: <span class="ninjadash-username">{item.causer ? item.causer.nome : ' - '}</span>,
          subject_type: item.subject_type,
          subject_id: item.subject_id,
          event: item.event,
          created_at: <span> { formatDateTime(item.created_at) } </span>,
          alteracoes: item.properties.attributes  ? <ul>{ Object.keys(item.properties.attributes).map( key => {
            return <li> <b>{key}: </b>  De: {item.properties.old ? item.properties.old[key] : ' '} <b> Para => </b>  { item.properties.attributes[key] ? item.properties.attributes[key] : ''}</li>
          })}</ul> : ''
        };
      })
    );   

    const showLog = () =>{
      data.showModal = true
      data.filter.attributes = '';
      data.filter.page = 1
      getData()
    }

    return {
      props,
      data,
      dataSource,
      dataColumns,
      debounceGetData,
      getData,
      showLog
    };
  },
});
</script>
<style lang="scss" scoped>
.ant-modal {
  width: 90% !important;   
}


</style>