export const resolveError = (error) => {
  console.log("Error Request: ", error.response);

  if(!error.response){
    return "Serviço indisponível, tente novamente mais tarde!";
  }

  if (error.response.status === 422  ) {
    return Object.keys(error.response.data.errors)
      .map((key) => {
        let e = error.response.data.errors[key].join(", ");
        return `Campo: ${key} - ${e}`;
      }).join(" | ");
  }
 
  if (error.response.status === 400) {
    return error.response.data.errors.join(', ');
  }
  
  if (error.response.status === 404) {
    return "O Recurso que está tentando acessar não existe";
  }

  if (error.response.status === 403) {
    return Object.keys(error.response.data.errors)
      .map((key) => {
        let e = error.response.data.errors[key].join(", ");
        return `${e}`;
      }).join(" | ");
  }

  if (error.response.status === 500) {
    return 'Ocorreu um erro interno no servidor!'
  }
};
