import { Api } from "@/services/Api.js";
import axios from 'axios';

export class LoginService extends Api {
    constructor() {
        super();
        // this.headers = {}
        this.headers = this.getHeaders()
    }

    loginUser(username,password) {
        let form = {
            email: username,
            password: password,
            grant_type: 'password',
            client_id: this.CLIENT_ID,
            client_secret: this.CLIENT_SECRET,
            scope: ""
        }
        return axios.post(
            `${this.API_URI}/api/auth/login`, form
        );
    }

    getAuthenticatedUser(){
        let u = localStorage.getItem('auth.access_token');
        if (u.length > 0)
            return JSON.parse(u)
        return null

    }



    logout(){
        return axios.post(
            `${this.API_URI}/api/auth/logout`, {}, {headers: this.getHeaders()}
        );
    }
    ping(){
        return axios.get(
            `${this.API_URI}/api/auth/ping`,  {headers: this.getHeaders()}
        );
    }
    me(){
        return axios.get(
            `${this.API_URI}/api/auth/me`,  {headers: this.getHeaders()}
        );
    }
}