import app from "../config/configApp";
import { createI18n } from "vue-i18n";
import en from "./localization/en/translation.json";
import esp from "./localization/es/translation.json";
import ptBR from "./localization/pt_BR/translation.json";
import store from "@/vuex/store";

const i18n = createI18n({
  legacy: false,
  locale: store.getters["lang/getLang"],
  fallbackLocale: "ptBR",
  messages: {
    en: en,
    esp: esp,
    ptBR: ptBR,
  },
});

store.watch(
  (state) => state.lang.lang,
  (newLocale) => {
    i18n.global.locale.value = newLocale;
  }
);

app.use(i18n);
